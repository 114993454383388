import { ms_to_mmss } from "../utilities/interface/datetime";
import "../base.ts";
import "./login/login.index";
var $ = require("jquery");
export function startTimer() {
    var time = 120;
    var interval = setInterval(function () {
        if (time > 0) {
            $(".vapp-login-page .code-box .form-tools p")
                .text("تا ارسال مجدد کد ")
                .removeClass("active");
            $(".vapp-login-page .code-box .form-tools span").text(ms_to_mmss(time));
            time--;
        }
        else {
            clearInterval(interval);
            $(".vapp-login-page .code-box .form-tools p")
                .text("ارسال مجدد کد")
                .addClass("active");
            $(".code-box .form-tools span").text('');
        }
    }, 1000);
}
export function setStep(selector) {
    $(".vapp-login-page .register-box").css("display", "none");
    $(".vapp-login-page .register-box".concat(selector)).css("display", "flex");
}
