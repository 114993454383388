import "./login.style.scss";
import InputField from "../../utilities/components/input/input.index";
import { post, responseValidator } from "../../utilities/interface/api";
import { startTimer } from "../register.index";
import Button from "../../utilities/components/button/button.index";
import { API } from "../../data/api";
var $ = require("jquery");
function registerPhoneNumberStep() {
    var phoneInput = new InputField("#phone-register");
    // const sendPhoneButton = new Button("#go-to-verify", () => {
    //   const phone = phoneInput.getVal();
    //   if (phone == "") phoneInput.showError("شماره خود را وارد کنید");
    //   else if (phone.length != 11 || phone.substring(0, 2) != "09")
    //     phoneInput.showError("شماره وارد شده صحیح نیست");
    //   else {
    //     return new Promise((resolve) => {
    //       startTimer();
    //       setStep(".code-box");
    //       registerVerifyStep();
    //       post(API.account.sendVerify, { phone: phone }).then((response) => {
    //         if (responseValidator(response)) {
    //           resolve(true);
    //           // userData.set(response.data);
    //         } else {
    //           //   Toast.show(
    //           //     ToastTypes.error,
    //           //     "خطا در ارسال پیامک",
    //           //     "برای شماره شما به تازکی پیامک کد ورود ارسال شده است. برای ارسال پیامک جدید بایستی دو دقیقه پس از پیامک اول مجددا اقدام نمایید",
    //           //     10000
    //           //   );
    //           resolve(true);
    //         }
    //       });
    //     });
    //   }
    // });
}
registerPhoneNumberStep();
function registerVerifyStep() {
    var codeInput = new InputField("#code-register");
    var sendCodeButton = new Button("#register-send-code", function () {
        var code = codeInput.getVal();
        if (code == "")
            codeInput.showError("کد تایید را وارد نمایید");
        //   else if (code.length != 11)
        //     codeInput.showError("کد تایید وارد شده صحیح نیست");
        else {
            return new Promise(function (resolve) {
                startTimer();
                //   setStep('.code-box');
                post(API.account.sendCode, { code: code }).then(function (response) {
                    if (responseValidator(response)) {
                        resolve(true);
                        // userData.set(response.data);
                    }
                    else {
                        //   Toast.show(
                        //     ToastTypes.error,
                        //     "خطا در ارسال پیامک",
                        //     "برای شماره شما به تازکی پیامک کد ورود ارسال شده است. برای ارسال پیامک جدید بایستی دو دقیقه پس از پیامک اول مجددا اقدام نمایید",
                        //     10000
                        //   );
                        resolve(true);
                    }
                });
            });
        }
    });
    $(".vapp-login-page .code-box .form-tools p").click(function () {
        var phone = new InputField("#phone-register").getVal();
        startTimer();
        return new Promise(function (resolve) {
            post(API.account.sendVerify, { phone: phone }).then(function (response) {
                if (responseValidator(response)) {
                    resolve(true);
                }
                else {
                    resolve(true);
                }
            });
        });
    });
}
